<script lang="ts" setup></script>

<template>
	<header class="flex-horizontal">
		<nuxt-link to="/">
			<Logo color="black" />
		</nuxt-link>

		<nuxt-link to="/contact">
			<button class="font-weight-medium">Feedback & Contact</button>
		</nuxt-link>
	</header>
</template>

<style scoped lang="scss">
	header {
		flex-shrink: 0;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		height: 56px;
		padding-left: 52px;
		padding-right: 52px;
		button {
			height: 100%;
			padding-left: 20px;
			padding-right: 20px;
			margin-right: -20px;
			font-size: 14px;
			color: black;
			@include mobile {
				padding-top: 8px;
			}
		}
		@include mobile {
			padding-left: 24px;
			padding-right: 24px;
		}
		.logo {
		}
	}
</style>
