<script lang="ts" setup>
	import { SpeedInsights } from "@vercel/speed-insights/vue";

	useHead({
		title: "Play charades, any time - Mimicmo",
		meta: [
			{
				name: "description",
				content: "Mimicmo is the destination to play charades by watching short-form videos of players acting out a word or phrase without speaking, while you try to guess what it is.",
			},
			{ property: "og:image", content: "https://mimicmo.com/images/og_image.jpg" },
			{ property: "og:image:width", content: "1200" },
			{ property: "og:image:height", content: "630" },
			{ property: "og:image:type", content: "image/jpeg" },
			{ property: "og:url", content: "https://mimicmo.com/" },
			{ property: "og:type", content: "website" },
			{ name: "theme-color", content: "#0b3e05", media: "(prefers-color-scheme: dark)" },
			{ name: "facebook-domain-verification", content: "m28f825ax4d2c0qchnv6upcwrp1vpr" },
		],
	});
</script>

<template>
	<SpeedInsights />
	<Header />

	<slot />
</template>

<style scoped lang="scss">
	html {
	}
</style>
